import { MessageBar, MessageBarType, Stack } from "@fluentui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Calendar from "../components/General/Calendar/Calendar";
import Header from "../components/General/Header";
import BasicDropdown from "../components/General/Inputs/BasicDropdown";
import { IsMobileContext } from "../components/General/Layout";
import { isLoading } from "../components/General/Loading";
import MobileFilter from "../components/General/MobileFilter/MobileFilter";
import "../components/General/MobileFilter/MobileFilter.css";
import "../components/General/Sidebar/Sidebar.css";
import { parkingSpotsToObject } from "../helpers/Functions";
import { useTitle } from "../helpers/useTitle";
import {
  GetAvailableParkingSpots,
  GetLocations,
} from "../services/LocationService";
import { GetParkingSpot } from "../services/ParkingService";
import "../styles/custom.css";
import MobileReservationPage from "./MobileReservationPage";

const NewParkingReservation = ({ forUser }) => {
  const history = useHistory();
  const today = new Date();

  const [yearAndMonth, setYearAndMonth] = useState([
    today.getFullYear(),
    today.getMonth() + 1,
  ]);
  const [items, setItems] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [period, setPeriod] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [lastTimeout, setLastTimeout] = useState(null);
  const [expanded, setExpanded] = useState(true);
  const [parking, setParking] = useState(false);
  const isMobile = useContext(IsMobileContext);
  const { t, i18n } = useTranslation();
  useTitle("New parking reservation");

  const periods = [
    { key: "halfDay", text: t("period.halfDay") },
    { key: "oneDay", text: t("period.oneDay") },
  ];

  useEffect(() => {
    checkParking();
    getLocations();
  }, []);

  useEffect(() => {
    getParkingSpots();
  }, [selectedLocation, period]);

  const checkParking = async () => {
    let parkingSpot = await GetParkingSpot("");
    parkingSpot.length === 0 ? history.push("/") : setParking(true);
  };

  const getParkingSpots = async () => {
    if (selectedLocation) {
      let parameter = {};
      let promises = [];
      if (forUser != null) parameter = { ...parameter, UserID: forUser.key };
      if (!period) return;
      let tempArray = [];
      if (period.key === "halfDay") {
        let morningParameters = { ...parameter, Period: "morning" };
        let afternoonParameters = { ...parameter, Period: "afternoon" };
        promises.push(
          GetAvailableParkingSpots(
            selectedLocation.key,
            morningParameters
          ).then((ps) =>
            tempArray.push(...ps.map((r) => parkingSpotsToObject(r, "morning")))
          )
        );
        promises.push(
          GetAvailableParkingSpots(
            selectedLocation.key,
            afternoonParameters
          ).then((ps) =>
            tempArray.push(
              ...ps.map((r) => parkingSpotsToObject(r, "afternoon"))
            )
          )
        );
        await Promise.all(promises);
      } else {
        parameter = { ...parameter, Period: period.key };
        await GetAvailableParkingSpots(selectedLocation.key, parameter).then(
          (ps) => tempArray.push(...ps.map((r) => parkingSpotsToObject(r)))
        );
      }
      setItems(tempArray);
    }
  };

  const getLocations = async () => {
    let locations = await GetLocations();
    let tempArray = locations
      .filter((l) => l.parkingAllowed)
      .map((location) => {
        return { text: location.name, key: location.id };
      });
    setLocations(tempArray);
  };

  const handleReservation = () => {
    return getParkingSpots();
  };

  const handleLocationSelect = (item) => {
    setSelectedLocation(item);
  };

  const handleMobilePopup = () => {
    setExpanded(!expanded);
  };

  const handleReservationError = useCallback(
    (error) => {
      setErrorMessage(error.response.data);
      if (lastTimeout) {
        clearTimeout(lastTimeout);
      }
      setLastTimeout(
        setTimeout(() => {
          setErrorMessage("");
        }, 4000)
      );
    },
    [lastTimeout]
  );

  const closeMessage = useCallback(() => {
    clearTimeout(lastTimeout);
    setErrorMessage("");
  }, [lastTimeout]);

  return (
    <>
      {isLoading(
        !parking,
        <>
          {!isMobile ? (
            <Stack className="page">
              <Header text={t("sidebar.parkingReservation")} />
              {errorMessage && (
                <MessageBar
                  messageBarType={MessageBarType.error}
                  dismissButtonAriaLabel="Close"
                  onDismiss={closeMessage}
                >
                  {errorMessage}
                </MessageBar>
              )}
              {locations.length === 0 && (
                <MessageBar
                  messageBarType={MessageBarType.warning}
                  isMultiline={true}
                >
                  {t("emptyMessages.noLocationPermission")}
                </MessageBar>
              )}
              <Stack tokens={{ childrenGap: 15 }} horizontal={true}>
                <BasicDropdown
                  options={locations}
                  handleChange={handleLocationSelect}
                  label={t("common.location")}
                  placeholder={t("common.selectLocation")}
                  disabled={locations.length === 0}
                />
                <BasicDropdown
                  label={t("common.period")}
                  options={periods}
                  handleChange={setPeriod}
                  placeholder={t("common.selectPeriod")}
                  defaultItem={{ key: "oneDay", text: t("period.oneDay") }}
                  disabled={locations.length === 0}
                />
              </Stack>
              <Calendar
                yearAndMonth={yearAndMonth}
                onYearAndMonthChange={(yearAndMonth) => {
                  setYearAndMonth(yearAndMonth);
                }}
                locationId={selectedLocation?.key}
                onReservation={handleReservation}
                forUser={forUser}
                items={items}
                isMobile={isMobile}
                period={period}
                onError={handleReservationError}
              />
            </Stack>
          ) : (
            <Stack verticalFill className="page">
              <Stack
                verticalFill
                className={`mobile-popup-content ${expanded ? "expanded" : ""}`}
              >
                {expanded ? (
                  <MobileFilter
                    selectedLocation={selectedLocation}
                    selectedPeriod={period}
                    handleMobilePopup={handleMobilePopup}
                    handleReset={() => {
                      setSelectedLocation(null);
                      setPeriod(null);
                    }}
                    date
                    periods={periods}
                    locations={locations}
                    handlePeriodSelection={(item) => setPeriod(item)}
                    handleLocationSelection={handleLocationSelect}
                  />
                ) : (
                  <MobileReservationPage
                    selectedLocation={selectedLocation}
                    selectedPeriod={period}
                    handleMobilePopup={handleMobilePopup}
                    handleReservationError={handleReservationError}
                    handleSelection={(item) => setYearAndMonth(item)}
                    date={yearAndMonth}
                    items={items}
                    forUser={forUser}
                    handleReservation={handleReservation}
                  />
                )}
              </Stack>
            </Stack>
          )}
        </>
      )}
    </>
  );
};

export default NewParkingReservation;
