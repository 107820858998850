import { Stack } from "@fluentui/react";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createDaysForCurrentMonth,
  createDaysForNextMonth,
  createDaysForPreviousMonth,
  daysOfWeek,
  getMonth,
  isWeekendDay,
} from "../../../helpers/calendar";
import { AddParkingReservation } from "../../../services/LocationService";
import {
  AddVacantAssignedParkingSpot,
  DeleteParkingReservationPromise,
} from "../../../services/ParkingService";
import { isLoading } from "../../General/Loading";
import "./Calendar.css";

Calendar.propTypes = {
  className: PropTypes.string,
  yearAndMonth: PropTypes.arrayOf(PropTypes.number).isRequired, // e.g. [2021, 6] for June 2021
  onYearAndMonthChange: PropTypes.func.isRequired,
  renderDay: PropTypes.func,
};

export default function Calendar({
  className = "",
  items = [],
  yearAndMonth = [2021, 6],
  onYearAndMonthChange,
  locationId,
  period,
  isMobile = false,
  onReservation = () => null,
  onError = (error) => null,
  renderDay = () => null,
  forUser,
}) {
  const [loadingDays, setLoadingDays] = useState([]);
  const [year, month] = yearAndMonth;
  const [height, setHeight] = useState(window.innerHeight);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getStatusText = (day) => {
    if (isMobile && ["Reserved", "Assigned"].includes(day.item.status)) {
      return day.item.parkingSpot;
    }

    return day.item.status + " " + day.item.parkingSpot;
  };

  const CalendarGridItem = ({ day, header, morning }) => {
    return (
      <div
        className={classNames("day-content-wrapper", {
          available: day.item && day.item.status == "Available",
          reserved:
            day.item && ["Reserved", "Assigned"].includes(day.item.status),
          unavailable: day.item && day.item.status == "Unavailable",
          "weekend-day": isWeekendDay(day.dateString),
          "current-month": day.isCurrentMonth,
        })}
        onClick={() => {
          if (
            day.item &&
            ["Available", "Reserved", "Assigned"].includes(day.item.status)
          ) {
            addReservation(day, { ...day.item, morning: morning });
          }
        }}
      >
        {header && <div className="day-grid-item-header">{day.dayOfMonth}</div>}
        <div className="day-grid-item-subtext">
          {day.item && ["Reserved", "Assigned"].includes(day.item.status)
            ? getStatusText(day)
            : "\u00a0"}
        </div>
      </div>
    );
  };

  let currentMonthDays = createDaysForCurrentMonth(year, month);
  let previousMonthDays = createDaysForPreviousMonth(
    year,
    month,
    currentMonthDays
  );
  let nextMonthDays = createDaysForNextMonth(year, month, currentMonthDays);
  let calendarGridDayObjects = [
    ...previousMonthDays,
    ...currentMonthDays,
    ...nextMonthDays,
  ];

  const handleMonthNavBackButtonClick = () => {
    let nextYear = year;
    let nextMonth = month - 1;
    if (nextMonth === 0) {
      nextMonth = 12;
      nextYear = year - 1;
    }
    onYearAndMonthChange([nextYear, nextMonth]);
  };

  const handleMonthNavForwardButtonClick = () => {
    let nextYear = year;
    let nextMonth = month + 1;
    if (nextMonth === 13) {
      nextMonth = 1;
      nextYear = year + 1;
    }
    onYearAndMonthChange([nextYear, nextMonth]);
  };

  async function addReservation(day, item) {
    let action = new Promise(() => {});
    setLoadingDays((prev) => [
      ...prev,
      new Date(day.dateString).toLocaleDateString(i18n.language),
    ]);
    if (item.status == "Available") {
      action = AddParkingReservation(locationId, {
        StartDate: day.dateString,
        EndDate: day.dateString,
        Morning: item.morning === "allDay" ? true : item.morning,
        Afternoon: item.morning === "allDay" ? true : !item.morning,
        UserID: forUser ? forUser.key : null,
        ParkingSpotId: item.parkingSpotID,
      });
    } else if (item.status == "Reserved") {
      action = DeleteParkingReservationPromise(item.reservationID);
    } else if (item.status == "Assigned") {
      action = AddVacantAssignedParkingSpot({
        AssignedParkingSpotID: item.assignedParkingSpot.id,
        Date: day.dateString,
      });
    }

    action
      .then((d) => onReservation(d).finally(() => finishLoading(day)))
      .catch((d) => {
        onError(d);
        finishLoading(day);
      });
  }

  const finishLoading = (day) => {
    setLoadingDays((prev) =>
      [...prev].filter(
        (d) => d != new Date(day.dateString).toLocaleDateString(i18n.language)
      )
    );
  };
  return (
    <div className="calendar-root" style={{ marginTop: "20px" }}>
      <Stack horizontal verticalAlign="center">
        <h4 className="month-span">{getMonth(month)}</h4>
        <div className="month-nav-arrow-buttons">
          <button onClick={handleMonthNavBackButtonClick}> {"<"}</button>
          <button onClick={handleMonthNavForwardButtonClick}>{">"}</button>
        </div>
      </Stack>
      <div className="navigation-header"></div>
      <div className="days-of-week">
        {daysOfWeek.map((day, index) => (
          <div
            key={day}
            className={classNames("day-of-week-header-cell", {
              "weekend-day": [6, 0].includes(index),
            })}
          >
            {window.innerWidth > 800 ? day : day.substring(0, 3)}
          </div>
        ))}
      </div>
      <div className="days-grid" style={{ maxHeight: height - 350 }}>
        {calendarGridDayObjects.map((day) => {
          return (
            <div key={day.dateString} className="day-grid-item-container">
              {isLoading(
                loadingDays.includes(
                  new Date(day.dateString).toLocaleDateString(i18n.language)
                ),
                <>
                  <CalendarGridItem
                    day={{
                      ...day,
                      item: items.filter(
                        (a) =>
                          new Date(a.date).toLocaleDateString(i18n.language) ===
                            new Date(day.dateString).toLocaleDateString(
                              i18n.language
                            ) &&
                          (a.reservations?.filter((r) => r.morning).length >
                            0 ||
                            a.reservations?.length === 0) &&
                          (a.period === "morning" || !a.period)
                      )[0],
                    }}
                    header={true}
                    morning={period?.key === "halfDay" ? true : "allDay"}
                  />
                  {(period?.key === "halfDay" ||
                    items.filter(
                      (a) =>
                        new Date(a.date).toLocaleDateString(i18n.language) ===
                          new Date(day.dateString).toLocaleDateString(
                            i18n.language
                          ) &&
                        a.reservations?.filter((r) => r.morning !== r.afternoon)
                          .length > 0
                    ).length > 0) && (
                    <CalendarGridItem
                      day={{
                        ...day,
                        item: items.filter(
                          (a) =>
                            new Date(a.date).toLocaleDateString(
                              i18n.language
                            ) ===
                              new Date(day.dateString).toLocaleDateString(
                                i18n.language
                              ) &&
                            (a.period === "afternoon" ||
                              a.reservations?.filter((r) => r.afternoon)
                                .length > 0)
                        )[0],
                      }}
                      header={false}
                      morning={false}
                    />
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
